.dash-body {
    background: #ECECED;
}

.dash-body::-webkit-scrollbar {
    display: none;
}

.grid-container {
    background-color: #ECECED;
    display: grid;
    grid-template-columns: 1fr; /* Side nav is hidden on mobile */
    grid-template-rows: 50px 1fr;
    grid-template-areas: 'header' 'main';
    height: 100vh;
    width: 100vw;
}

.sidenav {
    display: none;
    grid-area: sidenav;
    background-color: #0C808B;
}

.main-cards[type=dashboard] {
    column-count: 3;
    column-gap: 20px;
    margin: 20px;
}

@media only screen and (max-width: 34em) {
    .main-cards[type=dashboard] {
        display: grid;
        grid-template-columns: 375px;
        grid-template-rows: 425px 400px 300px 300px 300px;
        margin-left: calc((100vw - 590px) / 2);
        column-gap: 10px !important;
    }

    .card[type=dashboard]:first-child {
        display: none;
    }

    .card[type=dashboard]:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
        height: 485px;
        width: 525px;
        margin-left: 65px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(3) {
        grid-column: 1;
        grid-row: 2;
        height: 385px;
        width: 525px;
        align-items: center;
    }

    .card[type=dashboard]:nth-child(4) {
        grid-column: 1;
        grid-row: 3;
        height: 280px;
        width: 425px;
        margin-left: 75px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(5) {
        grid-column: 1;
        grid-row: 4;
        height: 280px;
        width: 425px;
        margin-left: 75px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(6) {
        grid-column: 1;
        grid-row: 5;
        height: 280px;
        width: 425px;
        margin-left: 75px;
        align-items: start;
    }

    .line {
        display: none;
    }

    .circle {
        display: none;
    }

    .alert {
        position: center;
        width: 350px;
        height: 65px;
        background-color: #DE5E3B;
        border-radius: 20px !important;
    }

        .alert h1 {
            text-align: center;
            font-family: 'Avenir Next';
            font-weight: 500;
            font-size: 24px;
            color: white;
            margin-left: 0px;
        }
}

@media only screen and (min-width: 34em) and (max-width: 60em) {
    .main-cards[type=dashboard] {
        display: grid;
        grid-template-columns: 475px;
        grid-template-rows: 425px 400px 300px 300px 300px;
        margin-left: calc((100vw - 600px) / 2);
        column-gap: 10px !important;
    }

    .card[type=dashboard]:first-child {
        display: none;
    }

    .card[type=dashboard]:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
        height: 485px;
        width: 525px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(3) {
        grid-column: 1;
        grid-row: 2;
        height: 385px;
        width: 525px;
        align-items: center;
    }

    .card[type=dashboard]:nth-child(4) {
        grid-column: 1;
        grid-row: 3;
        height: 280px;
        width: 425px;
        margin-left: 75px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(5) {
        grid-column: 1;
        grid-row: 4;
        height: 280px;
        width: 425px;
        margin-left: 75px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(6) {
        grid-column: 1;
        grid-row: 5;
        height: 280px;
        width: 425px;
        margin-left: 75px;
        align-items: start;
    }

    .line {
        display: none;
    }

    .circle {
        display: none;
    }

    .alert {
        position: center;
        width: 350px;
        height: 65px;
        background-color: #DE5E3B;
        border-radius: 20px !important;
    }

        .alert h1 {
            text-align: center;
            font-family: 'Avenir Next';
            font-weight: 500;
            font-size: 24px;
            color: white;
            margin-left: 0px;
        }
}

@media only screen and (min-width: 60em) and (max-width: 75em) {
    .main-cards[type=dashboard] {
        display: grid;
        grid-template-columns: 475px 425px;
        grid-template-rows: 280px 280px 280px;
        margin-left: calc((100vw - 1050px) / 2);
        column-gap: 10px !important;
    }

    .card[type=dashboard]:first-child {
        display: none;
    }

    .card[type=dashboard]:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
        height: 485px;
        width: 525px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(3) {
        grid-column: 1;
        grid-row: 2;
        height: 385px;
        width: 525px;
        margin-top: 200px;
        align-items: center;
    }

    .card[type=dashboard]:nth-child(4) {
        grid-column: 2;
        grid-row: 1;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(5) {
        grid-column: 2;
        grid-row: 2;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(6) {
        grid-column: 2;
        grid-row: 3;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .line {
        display: none;
    }

    .circle {
        display: none;
    }

    .alert {
        position: center;
        width: 400px;
        height: 65px;
        background-color: #DE5E3B;
        border-radius: 20px !important;
    }

        .alert h1 {
            text-align: center;
            font-family: 'Avenir Next';
            font-weight: 500;
            font-size: 28px;
            color: white;
            margin-left: 0px;
        }
}

@media only screen and (min-width: 75em) and (max-width: 115em) {
    .grid-container {
        grid-template-columns: 240px 1fr; /* Show the side nav for non-mobile screens */
        grid-template-areas: "header header" "sidenav main";
        height: 100vh;
        width: 100vw;
    }

    .sidenav {
        display: flex;
        flex-direction: column;
    }

    .main-cards[type=dashboard] {
        display: grid;
        grid-template-columns: 475px 425px;
        grid-template-rows: 280px 280px 280px;
        margin-left: calc(((100vw - 375px) - 900px) / 2);
        column-gap: 10px !important;
    }

    .card[type=dashboard]:first-child {
        display: none;
    }

    .card[type=dashboard]:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
        height: 485px;
        width: 525px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(3) {
        grid-column: 1;
        grid-row: 2;
        height: 385px;
        width: 525px;
        margin-top: 200px;
        align-items: center;
    }

    .card[type=dashboard]:nth-child(4) {
        grid-column: 2;
        grid-row: 1;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(5) {
        grid-column: 2;
        grid-row: 2;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(6) {
        grid-column: 2;
        grid-row: 3;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .line {
        display: none;
    }

    .circle {
        display: none;
    }

    .alert {
        position: center;
        width: 400px;
        height: 65px;
        background-color: #DE5E3B;
        border-radius: 20px !important;
    }

        .alert h1 {
            text-align: center;
            font-family: 'Avenir Next';
            font-weight: 500;
            font-size: 28px;
            color: white;
            margin-left: 0px;
        }
}

@media only screen and (min-width: 115em) {
    .grid-container {
        grid-template-columns: 240px 1fr; /* Show the side nav for non-mobile screens */
        grid-template-areas: "header header" "sidenav main";
        height: 100vh;
        width: 100vw;
    }

    .sidenav {
        display: flex;
        flex-direction: column;
    }

    .main-cards[type=dashboard] {
        display: grid;
        grid-template-columns: 525px 525px 425px;
        grid-template-rows: 280px 280px 280px;
        column-gap: 10px !important;
    }

    .card[type=dashboard]:first-child {
        grid-column: 1;
        grid-row: 1 / 3;
        height: 900px;
        width: 525px;
        align-items: center;
    }

    .card[type=dashboard]:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
        height: 485px;
        width: 525px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(3) {
        grid-column: 2;
        grid-row: 2;
        height: 385px;
        width: 525px;
        margin-top: 200px;
        align-items: center;
    }

    .card[type=dashboard]:nth-child(4) {
        grid-column: 3;
        grid-row: 1;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(5) {
        grid-column: 3;
        grid-row: 2;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .card[type=dashboard]:nth-child(6) {
        grid-column: 3;
        grid-row: 3;
        height: 280px;
        width: 425px;
        align-items: start;
    }

    .line {
        margin-left: -325px;
        margin-top: -20px;
        width: 250px;
        border-bottom: 3px solid #232323;
        position: absolute;
    }

    .circle {
        margin-left: 0px;
        margin-top: 42.5px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: #232323;
        position: absolute;
    }

    .alert {
        position: center;
        width: 400px;
        height: 65px;
        background-color: #DE5E3B;
        border-radius: 20px !important;
    }

        .alert h1 {
            text-align: center;
            font-family: 'Avenir Next';
            font-weight: 500;
            font-size: 28px;
            color: white;
            margin-left: 0px;
        }
}

.main[type=dashboard] {
    grid-area: main;
    background-color: #ECECED;
}

.card[type=dashboard] {
    font-family: AvenirNext-Medium;
    font-size: 16px;
    color: #232323;
    float: left;
    width: 100%;
    background-color: transparent;
    -webkit-column-break-inside: avoid;
    padding: 1em 10%;
    box-sizing: border-box;
    outline: none;
    border: none;
    box-shadow: none;
    margin: 1em 10%;
}

    .card[type=dashboard] h2 {
        text-align: center;
        padding: 20px;
        font-family: 'Avenir Next';
        font-weight: 600;
        font-size: 22px;
        color: #232323;
        margin-left: 0px;
        padding-top: 12px;
    }

    .card[type=dashboard] h3 {
        text-align: left !important;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: GothamBold;
        font-weight: 600;
        font-size: 28px;
        color: #232323;
        margin-left: 0px;
        padding-top: 0px;
    }

    .card[type=dashboard] h4 {
        text-align: left !important;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: 'Avenir Next';
        font-weight: 500;
        font-size: 14px;
        color: #232323;
        margin-left: 0px;
        margin-top: -10px;
        padding-top: 0px;
    }

    .card[type=dashboard] h5 {
        text-align: left !important;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: 'Avenir Next';
        font-weight: 600;
        font-size: 20px;
        color: #696969;
        margin-left: 0px;
        padding-top: 40px;
    }

    .card[type=dashboard] h6 {
        text-align: left !important;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: 'Avenir Next';
        font-weight: 600;
        font-size: 20px;
        color: #696969;
        margin-left: 0px;
        padding-top: 40px;
    }


.button[type=purchase] {
    position: center;
    background-color: #48AC6D;
    padding: 5px;
    font-family: GothamBold;
    border: 0;
    width: 260px !important;
    font-size: 22px;
    border-radius: 25px;
    color: #232323;
    cursor: pointer;
    box-shadow: 0 0 5px #48AC6D;
}

    .button[type=purchase]:hover {
        outline: none;
        background-color: #ECECED;
        box-shadow: 0 0 5px #48AC6D;
        color: #232323;
    }

.wrap {
    width: 100%;
    margin: 0 auto;
}

.bar-axis {
    margin-top: -2.5%;
    width: 47px;
    height: 150px;
    border-left: 3px solid #232323;
    position: absolute;
    z-index: 3;
}

.bargraph {
    padding: 0.5em;
    border-radius: 2.5px;
    margin: 1em 0;
    width: 0;
    color: white;
    position: relative;
    transition: width 2s, background .2s;
    -webkit-transform: translate3d(0,0,0);
    clear: both;
}

.label {
    font-size: 100%;
    padding: 1em;
    width: 100%;
    display: inline-block;
    position: relative;
    z-index: 2;
    font-weight: 400;
    font-family: 'Avenir Next';
}

.label-actual {
    font-size: 100%;
    padding: 5px;
    padding-left: 15px;
    margin-top: -60px;
    color: white;
    width: 100%;
    display: inline-block;
    position: absolute;
    z-index: 2;
    font-weight: 400;
    font-family: 'Avenir Next';
}

.count {
    position: absolute;
    left: calc(100% + 20px);
    font-size: 110%;
    width: 100px;
    font-weight: 700;
    color: #232323;
    font-family: 'Avenir Next';
}

.toggle-button[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.dashboard-label {
    cursor: pointer;
    text-indent: 0px;
    background-color: gray;
    width: 100px;
    height: 50px;
    display: block;
    border-radius: 100px;
    position: relative;
    box-shadow: 0 0 3px #696969;
}

    .dashboard-label[type=water]:after {
        content: attr(water_low);
        text-align: center !important;
        color: #696969;
        font-size: 15px;
        padding-top: 12px;
        position: absolute;
        top: 4px;
        left: 4px;
        width: 92px;
        height: 42px;
        background-color: white;
        border-radius: 90px;
        transition: 0.3s;
    }

    .dashboard-label[type=harvest]:after {
        content: attr(next_harvest);
        text-align: center !important;
        color: #696969;
        font-size: 15px;
        padding-top: 12px;
        position: absolute;
        top: 4px;
        left: 4px;
        width: 92px;
        height: 42px;
        background-color: white;
        border-radius: 90px;
        transition: 0.3s;
    }

    .dashboard-label[type=lighting]:after {
        content: attr(led_level);
        text-align: center !important;
        color: #696969;
        font-size: 15px;
        padding-top: 12px;
        position: absolute;
        top: 4px;
        left: 4px;
        width: 92px;
        height: 42px;
        background-color: white;
        border-radius: 90px;
        transition: 0.3s;
    }

    .dashboard-label:hover:after {
        width: 92px;
    }

    .dashboard-label:active:after {
        width: 92px;
        color: #DE5E3B;
    }

.toggle-button:checked + .dashboard-label {
    background-color: #DE5E3B;
    box-shadow: 0 0 3px #DE5E3B;
    color: #DE5E3B
}

    .toggle-button:checked + .dashboard-label:after {
        background-color: white;
        box-shadow: 0 0 3px #DE5E3B;
        color: #DE5E3B
    }

.button-header {
    text-align: center !important;
    margin-top: -50px;
    margin-left: 0px;
    color: #696969;
    font-size: 15px;
    color: #696969;
}