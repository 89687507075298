@media only screen and (max-width: 34em) {
    .notification-text[type=white] {
        text-align: left !important;
        text-indent: 5px;
        padding: 20px;
        padding-right: 40px;
        font-family: 'Avenir Next';
        font-weight: 400;
        font-size: 14px;
        color: white;
    }

    .notification-text[type=black] {
        text-align: left !important;
        padding: 20px;
        padding-right: 40px;
        font-family: 'Avenir Next';
        font-weight: 400;
        font-size: 14px !important;
        color: #232323;
    }

    .notification[type=success] {
        position: relative;
        background-color: #368568;
        color: white;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #409C7B;
    }

    .notification[type=warning] {
        position: relative;
        background-color: #DE5E3B;
        color: white;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #CF5838;
    }

    .notification[type=info] {
        position: relative;
        background-color: white;
        color: #232323;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #999999;
    }

    .notification-close {
        background: transparent;
        outline: none;
        border: none;
        float: right;
        margin-top: -25px;
        margin-right: -30px;
    }
}

@media only screen and (min-width: 34em) and (max-width: 50em) {
    .notification-text[type=white] {
        text-align: left !important;
        text-indent: 5px;
        padding: 15px;
        font-family: 'Avenir Next';
        font-weight: 400;
        font-size: 14px;
        color: white;
    }

    .notification-text[type=black] {
        text-align: left !important;
        padding: 20px;
        padding-right: 40px;
        font-family: 'Avenir Next';
        font-weight: 400;
        font-size: 14px !important;
        color: #232323;
    }

    .notification[type=success] {
        position: relative;
        background-color: #368568;
        color: white;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #409C7B;
    }

    .notification[type=warning] {
        position: relative;
        background-color: #DE5E3B;
        color: white;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #CF5838;
    }

    .notification[type=info] {
        position: relative;
        background-color: white;
        color: #232323;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #999999;
    }

    .notification-close {
        background: transparent;
        outline: none;
        border: none;
        float: right;
        margin-top: -10px;
        margin-right: -30px;
    }
}

@media only screen and (min-width: 50em) and (max-width: 75em) {
    .notification-text[type=white] {
        text-align: left !important;
        text-indent: 5px;
        padding: 15px;
        font-family: 'Avenir Next';
        font-weight: 400;
        font-size: 16px;
        color: white;
    }

    .notification-text[type=black] {
        text-align: left !important;
        padding: 20px;
        padding-right: 40px;
        font-family: 'Avenir Next';
        font-weight: 400;
        font-size: 16px !important;
        color: #232323;
    }


    .notification[type=success] {
        position: relative;
        background-color: #368568;
        color: white;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #409C7B;
    }

    .notification[type=warning] {
        position: relative;
        background-color: #DE5E3B;
        color: white;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #CF5838;
    }

    .notification[type=info] {
        position: relative;
        background-color: white;
        color: #232323;
        border-radius: 7.5px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        box-shadow: 0 0 2px #999999;
    }

    .notification-close {
        background: transparent;
        outline: none;
        border: none;
        float: right;
        margin-top: 0px;
        margin-right: -30px;
    }
}

@media only screen and (min-width: 75em) {
    .notification-text {
        text-align: left !important;
        padding: 20px;
        padding-right: 40px;
        font-family: 'Avenir Next';
        font-weight: 400;
        font-size: 16px !important;
    }

    .notification[type=success] {
        position: relative;
        background-color: #368568;
        color: white;
        border-radius: 7.5px;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        box-shadow: 0 0 2px #409C7B;
    }

    .notification[type=warning] {
        position: relative;
        background-color: #DE5E3B;
        color: white;
        border-radius: 7.5px;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        box-shadow: 0 0 2px #CF5838;
    }

    .notification[type=info] {
        position: relative;
        background-color: white;
        color: #232323;
        border-radius: 7.5px;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        box-shadow: 0 0 2px #999999;
    }

    .notification-close {
        background: transparent;
        outline: none;
        border: none;
        float: right;
        margin-top: -5px;
        margin-right: -35px;
    }
}

.notification-body {
    background: #ECECED;
    height: 1200px;
    overflow: hidden;
}

.main[type=notifications] {
    grid-area: main;
    background-color: #ECECED;
}

.main-cards[type=notifications] {
    display: grid;
    grid-template-columns: 85%;
    grid-template-rows: 900px;
    margin-top: 30px;
    margin-bottom: 100px;
}

.card[type=notifications] {
    font-family: AvenirNext-Medium;
    font-size: 16px;
    color: #232323;
    float: left;
    width: 100%;
    background-color: #F2F2F2;
    -webkit-column-break-inside: avoid;
    padding: 1em 10%;
    box-sizing: border-box;
    box-shadow: 0 0 5px #BABABA;
    margin: 1em 10%;
    padding-bottom: 20px;
    overflow: hidden;
}

    .card[type=notifications] h1 {
        text-align: left !important;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: 'Avenir Next';
        font-weight: 500;
        font-size: 26px;
        color: #232323;
        margin-left: 0px;
        padding-top: 15px;
    }

    /* Force varying heights to simulate dynamic content */
    .card[type=notifications]:first-child {
        grid-column: 1;
        grid-row: 1;
        height: 900px;
        width: 100%;
        border-radius: 25px;
        align-items: start;
    }

    .notification-close:active {
        background: transparent;
        outline: none;
        border: none;
    }

    .notification-close:focus {
        background: transparent;
        outline: none;
        border: none;
    }