.status-body {
    background: #ECECED;
}

.status-body::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 34em) {
    .main-cards[type=status] {
        display: grid;
        grid-template-columns: 45vw 45vw;
        grid-template-rows: 300px calc(90vw + 50px) 300px 300px 300px;
        grid-column-gap: 20px;
        justify-content: center;
        margin-left: -70px;
    }

    .card[type=status]:first-child {
        grid-column: 1;
        grid-row: 2 / 4;
        padding: 0 !important;
        height: 900px;
        width: 200%;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .card[type=status]:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
        height: 235px;
        width: 200%;
        border-radius: 50px;
        top: 50px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(3) {
        grid-column: 1;
        grid-row: 4;
        height: 265px;
        border-radius: 50px;
        width: 200%;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(4) {
        grid-column: 1;
        grid-row: 5;
        height: 265px;
        width: 200%;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(5) {
        grid-column: 1;
        grid-row: 6;
        height: 265px;
        width: 200%;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    #select-terrafarm1 {
        display: none;
    }

    #select-tray1 {
        display: none;
    }

    #select-terrafarm2 {
        display: none;
    }

    #select-tray2 {
        display: none;
    }

    #led-info1 {
        display: none;
    }

    #led-info2 {
        display: none;
    }

    #led-info3 {
        margin-left: calc(-70% + 60px);
    }

    #climate-info1 {
        display: none;
    }

    #nutrient-info1 {
        display: none;
    }

    #climate-header1 {
        display: none;
    }

    #nutrient-header1 {
        display: none;
    }

    #low-light {
        position: absolute;
        font-size: 16px;
        margin-top: 55px;
        margin-left: 190px;
    }

    .button[type="led-submit"] {
        position: absolute;
        margin-left: calc(86% - 170px);
        margin-top: 190px;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 150px;
        font-size: 20px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .light-mode-label {
        position: absolute;
        margin-left: 10%;
        margin-top: 88px;
        background-color: white;
        border: 0;
        width: 10px;
        height: 10px;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 5px #48B08C;
    }

    .button[type=small-white-led] {
        position: absolute;
        margin-left: -70%;
        margin-top: 84px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }

    .button[type=small-white-climate] {
        position: absolute;
        margin-left: -80px;
        margin-top: 159px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }
}

@media only screen and (min-width: 34em) and (max-width: 45em) {
    .main-cards[type=status] {
        display: grid;
        grid-template-columns: 45vw 45vw;
        grid-template-rows: 300px 620px 300px 300px 300px;
        grid-column-gap: 20px;
        justify-content: center;
        margin-left: -60px;
    }

    .card[type=status]:first-child {
        grid-column: 1;
        grid-row: 2 / 4;
        padding: 0 !important;
        height: 900px;
        width: 200%;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .card[type=status]:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
        height: 235px;
        width: 200%;
        border-radius: 50px;
        top: 50px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(3) {
        grid-column: 1;
        grid-row: 4;
        height: 265px;
        border-radius: 50px;
        width: 200%;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(4) {
        grid-column: 1;
        grid-row: 5;
        height: 265px;
        width: 200%;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(5) {
        grid-column: 1;
        grid-row: 6;
        height: 265px;
        width: 200%;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    #select-terrafarm1 {
        display: none;
    }

    #select-tray1 {
        display: none;
    }

    #select-terrafarm2 {
        display: none;
    }

    #select-tray2 {
        display: none;
    }

    #led-info1 {
        display: none;
    }

    #led-info2 {
        display: none;
    }

    #led-info3 {
        margin-left: calc(-70% + 60px);
    }

    #climate-info1 {
        display: none;
    }

    #nutrient-info1 {
        display: none;
    }

    #climate-header1 {
        display: none;
    }

    #nutrient-header1 {
        display: none;
    }

    #low-light {
        position: absolute;
        font-size: 16px;
        margin-top: 55px;
        margin-left: 210px;
    }

    .button[type="led-submit"] {
        position: absolute;
        margin-left: calc(86% - 170px);
        margin-top: 190px;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 150px;
        font-size: 20px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .light-mode-label {
        position: absolute;
        margin-left: 10%;
        margin-top: 88px;
        background-color: white;
        border: 0;
        width: 10px;
        height: 10px;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 5px #48B08C;
    }

    .button[type=small-white-led] {
        position: absolute;
        margin-left: -70%;
        margin-top: 84px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }

    .button[type=small-white-climate] {
        position: absolute;
        margin-left: -80px;
        margin-top: 159px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }
}

@media only screen and (min-width: 45em) and (max-width: 75em) {
    .main-cards[type=status] {
        display: grid;
        grid-template-columns: 45vw 45vw;
        grid-template-rows: 300px 620px 300px 300px;
        grid-column-gap: 20px;
        justify-content: center;
        margin-left: -50px;
    }

    .card[type=status]:first-child {
        grid-column: 1;
        grid-row: 2 / 4;
        padding: 0 !important;
        height: 900px;
        width: 45vw;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .card[type=status]:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
        height: 235px;
        width: 200%;
        border-radius: 50px;
        top: 50px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
    }

    .card[type=status]:nth-child(3) {
        grid-column: 1;
        grid-row: 4;
        height: 265px;
        border-radius: 50px;
        width: 200%;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
    }

    .card[type=status]:nth-child(4) {
        grid-column: 1;
        grid-row: 5;
        height: 265px;
        width: 95%;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(5) {
        grid-column: 2;
        grid-row: 5;
        height: 265px;
        width: 95%;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    #select-terrafarm1 {
        display: none;
    }

    #select-tray1 {
        display: none;
    }

    #led-info1 {
        display: none;
    }

    #led-info3 {
        display: none;
    }

    #climate-info1 {
        display: none;
    }

    #nutrient-info1 {
        display: none;
    }

    #climate-header1 {
        display: none;
    }

    #nutrient-header1 {
        display: none;
    }

    .button[type="led-submit"] {
        position: absolute;
        margin-left: calc(89% - 170px);
        margin-top: 190px;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 150px;
        font-size: 20px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .light-mode-label {
        position: absolute;
        margin-left: 290px;
        margin-top: 33px;
        background-color: white;
        border: 0;
        width: 10px;
        height: 10px;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 5px #48B08C;
    }

    .button[type=small-white-led] {
        position: absolute;
        margin-left: 0px;
        margin-top: 84px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }

    .button[type=small-white-climate] {
        position: absolute;
        margin-left: -80px;
        margin-top: 159px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }
}

@media only screen and (min-width: 75em) and (max-width: 115em) {
    .main-cards[type=status] {
        display: grid;
        grid-template-columns: 400px 400px;
        grid-template-rows: 300px 620px 300px 300px;
        grid-column-gap: 20px;
        justify-content: center;
    }

    .card[type=status]:first-child {
        grid-column: 1;
        grid-row: 2 / 4;
        padding: 0 !important;
        height: 900px;
        width: 700px;
        margin-left: 95px;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .card[type=status]:nth-child(2) {
        grid-column: 1;
        grid-row: 1;
        height: 235px;
        width: 820px;
        border-radius: 50px;
        top: 50px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
    }

    .card[type=status]:nth-child(3) {
        grid-column: 1;
        grid-row: 4;
        height: 265px;
        border-radius: 50px;
        width: 820px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
    }

    .card[type=status]:nth-child(4) {
        grid-column: 1;
        grid-row: 5;
        height: 265px;
        width: 400px;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(5) {
        grid-column: 2;
        grid-row: 5;
        height: 265px;
        width: 400px;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    #select-terrafarm2 {
        display: none;
    }

    #select-tray2 {
        display: none;
    }

    #led-info2 {
        display: none;
    }

    #led-info3 {
        display: none;
    }

    #climate-info2 {
        display: none;
    }

    #nutrient-info2 {
        display: none;
    }

    #climate-header2 {
        display: none;
    }

    #nutrient-header2 {
        display: none;
    }

    .button[type="led-submit"] {
        position: absolute;
        margin-left: 565px;
        margin-top: 190px;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 150px;
        font-size: 20px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .light-mode-label {
        position: absolute;
        margin-left: 290px;
        margin-top: 33px;
        background-color: white;
        border: 0;
        width: 10px;
        height: 10px;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 5px #48B08C;
    }

    .button[type=small-white-led] {
        position: absolute;
        margin-left: 0px;
        margin-top: 84px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }

    .button[type=small-white-climate] {
        position: absolute;
        margin-left: -215px;
        margin-top: 159px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }
}

@media only screen and (min-width: 115em) {
    .main-cards[type=status] {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 750px 400px 400px;
        grid-template-rows: 300px 300px 300px;
        grid-column-gap: 20px;
    }

    .card[type=status]:first-child {
        grid-column: 1;
        grid-row: 1 / 3;
        padding: 0 !important;
        height: 900px;
        width: 700px;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .card[type=status]:nth-child(2) {
        grid-column: 2 / 3;
        grid-row: 1;
        height: 235px;
        width: 820px;
        border-radius: 50px;
        top: 50px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
    }

    .card[type=status]:nth-child(3) {
        grid-column: 2 / 3;
        grid-row: 2;
        height: 265px;
        border-radius: 50px;
        width: 820px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 50px;
    }

    .card[type=status]:nth-child(4) {
        grid-column: 2;
        grid-row: 3;
        height: 265px;
        width: 400px;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    .card[type=status]:nth-child(5) {
        grid-column: 3;
        grid-row: 3;
        height: 265px;
        width: 400px;
        border-radius: 50px;
        top: -20px;
        box-shadow: 0 0 5px #BABABA;
        background-color: #F2F2F2;
        padding-left: 40px;
        align-items: center !important;
    }

    #select-terrafarm2 {
        display: none;
    }

    #select-tray2 {
        display: none;
    }

    #led-info2 {
        display: none;
    }

    #led-info3 {
        display: none;
    }

    #climate-info2 {
        display: none;
    }

    #nutrient-info2 {
        display: none;
    }

    #climate-header2 {
        display: none;
    }

    #nutrient-header2 {
        display: none;
    }

    .button[type="led-submit"] {
        position: absolute;
        margin-left: 565px;
        margin-top: 190px;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 150px;
        font-size: 20px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .light-mode-label {
        position: absolute;
        margin-left: 290px;
        margin-top: 33px;
        background-color: white;
        border: 0;
        width: 10px;
        height: 10px;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 5px #48B08C;
    }

    .button[type=small-white-led] {
        position: absolute;
        margin-left: 0px;
        margin-top: 84px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }

    .button[type=small-white-climate] {
        position: absolute;
        margin-left: -215px;
        margin-top: 159px !important;
        background-color: #F2F2F2;
        border: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #232323;
        border-radius: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px #232323;
        box-shadow: 0 0 1px #232323 inset;
    }
}

.main[type=status] {
    grid-area: main;
    background-color: #ECECED;
}

.card[type=status] {
    font-family: AvenirNext-Medium;
    font-size: 16px;
    color: #232323;
    float: left;
    align-items: start;
    width: 100%;
    background-color: white;
    -webkit-column-break-inside: avoid;
    padding: 1em 10%;
    box-sizing: border-box;
    box-shadow: 0 0 5px #BABABA;
    margin: 1em 10%;
}
.card[type=status] h1 {
    text-align: left;
    font-family: GothamBold;
    font-size: 24px;
    color: #232323;
    line-height: 36px;
    margin-left: 0px;
    padding-top: 20px;
}

.card[type=status] h2 {
    text-align: left;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 24px;
    color: #232323;
    line-height: 36px;
    margin-left: 320px;
    margin-top: -62px;
    padding-top: 20px;
}

.card[type=status] h3 {
    text-align: left;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 16px;
    color: #232323;
    line-height: 30px;
    margin-left: 35px;
    margin-top: -5px;
    padding-top: 20px;
    margin-bottom: 15px;
}

.card[type=status] h4 {
    position: absolute;
    background-color: transparent;
    font-family: 'Avenir Next';
    font-weight: 600;
    color: #232323 !important;
    font-size: 24px;
    border: none;
    margin-left: 290px;
    margin-top: 95px;
}

.light-mode-switch[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.light-mode-switch:checked + .light-mode-label {
    background-color: #48B08C;
}

    .button[type=small-white-led]:hover {
        outline: none;
        background-color: #ECECED;
        outline: none;
    }

    .button[type=small-white-led]:focus {
        background-color: #ECECED;
        outline: none;
    }

    .button[type="led-submit"]:hover {
        outline: none;
    }

    .button[type="led-submit"]:active {
        background-color: #ECECED;
        color: #DE5E3B;
        box-shadow: 0 0 5px #FF6D45;
        outline: none;
    }

    .button[type="led-submit"]:focus {
        outline: none;
    }

    .button[type=small-white-climate]:hover {
        outline: none;
        background-color: #ECECED;
    }

    .button[type=small-white-climate]:focus {
        background-color: #ECECED;
        outline: none;
    }

    .button[type=small-white-climate]:active {
        background-color: #ECECED;
        outline: none;
    }

.drop-button[type=status] {
    float: left;
    width: 250px;
    margin-top: 20px;
    padding: 7.5px;
    text-align: center;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 22px;
    background-color: #48AC6D;
    border-radius: 25px;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    color: #F0F0F0 !important;
    opacity: 0.85;
}

    .drop-button[type=status]:hover {
        text-decoration: none;
        opacity: 1;
    }

    .drop-button[type=status]:focus {
        border-radius: 25px 25px 0 0;
        opacity: 1;
    }

.dropdown-menu[type=status] {
    position: relative;
    text-indent: 25px;
    width: 250px;
    padding: 7.5px;
    margin-top: 0px !important;
    padding-bottom: 20px;
    border-radius: 0 0 25px 25px;
    background-color: #48AC6D;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.dropdown-menu[type=status-tf] {
    position: relative;
    text-indent: 25px;
    width: 250px;
    padding: 7.5px;
    padding: 7.5px;
    margin-top: 0px !important;
    padding-bottom: 20px;
    border-radius: 0 0 25px 25px;
    background-color: #48AC6D;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.dropdown-item[type=status] > a {
    display: block;
    padding: 0;
    margin-left: -12px;
    font-family: AvenirNext-Medium;
    font-size: 18px;
    text-align: left;
    text-decoration: none;
    color: #F0F0F0;
}

    .dropdown-item[type=status] > a:hover {
        color: #DE5E3B;
    }