@media only screen and (max-width: 31.33em) {
    .navbar {
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 375vw;
        background-position: 45%;
        width: 100vw;
        height: 50px;
        overflow: hidden;
    }

    .header1 {
        display: none;
    }

    .brand-container {
        float: left;
    }

    .brand {
        display: none;
    }

    .social-container {
        margin-right: 0px;
        margin-top: 0px;
        float: right;
        margin-left: 100px;
    }

    .nav-item {
        font-size: 0px;
        visibility: hidden;
        display: none;
    }

    .nav-modal-backdrop {
        width: 100vw;
        height: 100vh;
        background: white;
        margin: 0;
        margin-top: -30px;
        padding-top: 10px;
    }

    .nav-modal-main {
        background: white;
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .modal-list {
        padding-top: 50px;
        padding-left: 0;
        list-style: none;
    }

    .modal-item {
        width: 100%;
        font-family: 'Avenir Next';
        color: black;
        font-size: 18px;
        font-weight: 400;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #6B6B6B;
        cursor: pointer;
    }

    .menu {
        display: block;
    }
}

@media only screen and (min-width: 31.33em) and (max-width: 50em) {
    .navbar {
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 350vw;
        background-position: 45%;
        width: 100vw;
        height: 50px;
        overflow: hidden;
    }

    .header1 {
        font-size: 14px;
        color: white;
        font-family: 'Avenir Next';
        font-weight: 400;
    }

    .brand-container {
        float: left;
    }

    .brand {
        display: none;
    }

    .social-container {
        margin-right: 0px;
        margin-top: 0px;
        float: right;
        margin-left: 300px;
        width: 100px;
    }

    .nav-item {
        font-size: 0px;
        visibility: hidden;
        display: none;
    }

    .nav-modal-backdrop {
        width: 100vw;
        height: 100vh;
        background: white;
        margin: 0;
        margin-top: -30px;
        padding-top: 10px;
    }

    .nav-modal-main {
        background: white;
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .modal-list {
        padding-top: 50px;
        padding-left: 0;
        list-style: none;
    }

    .modal-item {
        width: 100%;
        font-family: 'Avenir Next';
        color: black;
        font-size: 18px;
        font-weight: 400;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #6B6B6B;
        cursor: pointer;
    }

    .menu {
        display: block;
    }
}

@media only screen and (min-width: 50em) and (max-width: 59em) {
    .navbar {
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 200vw;
        width: 100vw;
        height: 50px;
        overflow: hidden;
    }

    .header1 {
        font-size: 14px;
        color: white;
        font-family: 'Avenir Next';
        font-weight: 400;
    }

    .brand-container {
        float: left;
    }

    .brand {
        display: none;
    }

    .social-container {
        margin-right: 0px;
        margin-top: 0px;
        float: right;
        margin-left: 500px;
    }

    .nav-item {
        font-size: 2px;
        visibility: hidden;
        display: none;
    }

    .nav-modal-backdrop {
        width: 100vw;
        height: 100vh;
        background: white;
        margin: 0;
        margin-top: -30px;
        padding-top: 10px;
    }

    .nav-modal-main {
        background: white;
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .modal-list {
        padding-top: 50px;
        padding-left: 0;
        list-style: none;
    }

    .modal-item {
        width: 100%;
        font-family: 'Avenir Next';
        color: black;
        font-size: 18px;
        font-weight: 400;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #6B6B6B;
        cursor: pointer;
    }

    .menu {
        display: block;
    }
}

@media only screen and (min-width: 59em) and (max-width: 84em) {
    .navbar {
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 190vw;
        width: 100vw;
        height: 50px;
        overflow: hidden;
    }

    .header1 {
        font-size: 14px;
        color: white;
        font-family: 'Avenir Next';
        font-weight: 400;
    }

    .brand-container {
        width: 15%;
        margin-left: 0;
    }

    .brand {
        padding-top: 20px;
    }

    .social-container {
        margin-top: -20px;
        width: 15%;
        margin-left: 100%;
    }

    .nav-item-container {
        width: 75%;
        text-align: center;
        margin-top: 0px;
        margin-left: 0%;
    }

    .nav-item {
        font-size: 14px;
        color: white;
        font-family: 'Avenir Next';
        font-weight: 400;
        text-decoration: none;
        cursor: pointer;
        transition: 0.5s;
    }

        .nav-item>a:hover {
            font-size: 16px;
            transition: 0.5s;
        }

    .menu {
        display: none;
    }

    .nav-modal-backdrop {
        display: none;
    }

    .nav-modal-main {
        display: none;
    }

    .modal-list {
        display: none;
    }

    .modal-item {
        display: none;
    }
}

@media only screen and (min-width: 84em) {
    .navbar {
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 120vw !important;
        width: 100vw;
        height: 50px;
        overflow: hidden;
    }

    .header1 {
        font-size: 16px;
        color: white;
        font-family: 'Avenir Next';
        font-weight: 400;
    }

    .brand-container {
        width: 10%;
        margin-left: 0;
        padding-top: 20px;
    }

    .nav-item-container {
        width: 80%;
        text-align: center;
        margin-top: 0px;
        margin-left: 0%;
    }

        .nav-item {
            font-size: 16px;
            color: white;
            font-family: 'Avenir Next';
            font-weight: 400;
            text-decoration: none;
            cursor: pointer;
            transition: 0.5s;
        }

            .nav-item>a:hover {
                font-size: 18px;
                transition: 0.5s;
            }

    .social-container {
        margin-top: -20px;
        width: 10%;
        margin-left: 100%;
    }

    .menu {
        display: none;
    }

    .nav-modal-backdrop {
        display: none;
    }

    .nav-modal-main {
        display: none;
    }

    .modal-list {
        display: none;
    }

    .modal-item {
        display: none;
    }
}

    .nav-list {
        width: 100%;
        display: inline;
    }

    .nav-item {
        display: inline;
        padding-left: 20px;
        padding-right: 20px;
    }

    .social-item {
        float: right;
        display: inline;
    }