@media only screen and (max-width: 31.33em) {
    .landing {
        position: relative;
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 375vw;
        background-position: 45%;
        width: 100vw;
        height: 100vh;
    }

    .text1 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 40px;
        line-height: 80px;
        font-weight: 700;
        padding: 0;
        margin: 0;
    }

    .text2 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 26px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    .clear {
        float: left;
        background: transparent;
        color: white;
        font-family: 'Avenir Next';
        font-size: 18px;
        line-height: 44px;
        font-weight: 400;
        width: 150px;
        margin-left: calc(50vw - 100px);
        margin-top: 100px;
        border-radius: 25px;
        outline: none;
        border: solid white 1px;
        box-shadow: none;
        cursor: pointer;
    }

    .learn-more {
        color: white;
    }

        .learn-more:hover {
            color: white;
            text-decoration: none;
        }

    .main-cards1 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 450px;
        grid-column-gap: 5vw;
        grid-row-gap: 5vh;
        margin-top: 100px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 100px;
    }

    .main-cards2 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 50px 150px 600px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 350px;
        height: 400px;
    }

    .main-cards3 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 32px 400px 400px;
        grid-row-gap: 30px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 100px;
    }

    .main-cards4 {
        display: grid;
        grid-template-columns: 40w 40w;
        grid-template-rows: 350px 350px;
        grid-row-gap: 6.67px;
        grid-column-gap: 15px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 150px;
    }

    .main-cards5 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 750px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 200px;
    }

    .card[type="experience"]:first-child {
        grid-column: 1;
        grid-row: 1;
        height: 100%;
        width: 100%;
    }

    .card[type="experience"]:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
        height: 100%;
        width: 150px;
    }

    .card[type="experience"]:nth-child(3) {
        grid-column: 1;
        grid-row: 3;
        height: 100%;
        width: 100%;
    }

    .text3 {
        text-align: left;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 28px;
        line-height: 28px;
        font-weight: 600;
        padding: 0;
        margin: 0;
    }

    .text4 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 20px;
        margin: 0;
    }

    .text5 {
        text-align: left;
        color: black;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        padding-top: 65px;
        margin: 0;
    }

    .text6 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .text7 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .profile {
        display: none;
    }

    .overlay[type=left] {
        position: absolute;
        width: 80vw;
        height: 50vw;
        background: #022140;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
    }

    .overlay[type=right] {
        position: absolute;
        width: 80vw;
        height: 50vw;
        background: #022140;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -70px;
    }

    .text8[type=right] {
        position: absolute;
        text-align: right;
        color: #21C4B9;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        margin-top: 20px;
        width: calc(80vw - 20px);
        z-index: 2;
    }

    .text8[type=left] {
        position: absolute;
        text-align: left;
        color: #21C4B9;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        margin-top: 20px;
        margin-left: 20px;
        z-index: 2;
    }

    .text9[type=right] {
        position: absolute;
        text-align: right;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
        padding: 0;
        margin: 0;
        margin-top: 40px;
        width: calc(80vw - 20px);
        z-index: 2;
    }

    .text9[type=left] {
        position: relative;
        text-align: left;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
        padding: 0;
        margin: 0;
        margin-top: 40px;
        margin-left: 20px;
        width: 250px;
        z-index: 2;
    }

    .text10[type=right] {
        position: absolute;
        text-align: right;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 75px;
        padding-left: 20px;
        width: calc(80vw - 20px);
        margin: 0;
        z-index: 2;
    }

    .text10[type=left] {
        position: absolute;
        text-align: left;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 5px;
        padding-left: 20px;
        width: calc(80vw - 20px);
        margin: 0;
        z-index: 2;
    }

    .text11[type="right"] {
        position: relative;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        padding-top: 20px;
        width: 80vw;
        z-index: 2;
    }

    .text11[type="left"] {
        position: relative;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        margin-top: 175px !important;
        padding-top: 20px;
        width: 80vw;
        z-index: 2;
    }

    .feature {
        width: 100%;
        z-index: 0;
        opacity: 0.2;
        transition: 0.5s;
        border-radius: 10px;
        z-index: 2
    }

    .contact-info {
        float: left;
        height: 400px;
        width: 75%;
        background: #022140;
        margin-top: 60px;
        margin-left: 12.5%;
        border-radius: 10px;
    }

    .contact-form {
        margin-top: 50px;
        background: white;
        width: 80vw;
        height: 500px;
        margin-left: 10vw;
        padding-top: 0px;
        border-radius: 10px;
    }

    .contact {
        width: 56vw;
        height: 50px;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-left: 20px;
        border-radius: 10px;
    }

    .message {
        width: 55vw;
        height: 200px;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 20px;
        resize: none;
        border-radius: 10px;
    }

    .send {
        width: 30vw;
        height: 50px;
        margin-top: 15px;
        margin-left: 15vw;
        font-family: 'Avenir Next';
        font-size: 20px;
        font-weight: 600;
        color: #EFF3FE;
        background: #022140;
        border: none;
        outline: none;
        border-radius: 10px;
        transition: 0.25s;
    }
}

@media only screen and (max-width: 24em) {
    .main-cards1 {
        margin-bottom: 200px;
    }

    .main-cards2 {
        margin-bottom: 350px;
    }

    .text8[type=left] {
        font-size: 12px;
        line-height: 20px;
    }

    .text8[type=right] {
        font-size: 12px;
        line-height: 20px;
    }

    .text9[type=left] {
        font-size: 18px;
        line-height: 30px;
    }

    .text9[type=right] {
        font-size: 18px;
        line-height: 30px;
    }

    .text10[type=left] {
        font-size: 12px;
    }

    .text10[type=right] {
        font-size: 12px;
    }
}

@media only screen and (min-width: 31.33em) and (max-width: 50em) {
    .landing {
        position: relative;
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 350vw;
        background-position: 45%;
        width: 100vw;
        height: 100vh;
    }

    .text1 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 44px;
        line-height: 88px;
        font-weight: 700;
        padding: 0;
        margin: 0;
    }

    .text2 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    .clear {
        float: left;
        background: transparent;
        color: white;
        font-family: 'Avenir Next';
        font-size: 18px;
        line-height: 44px;
        font-weight: 400;
        width: 150px;
        margin-left: calc(50vw - 100px);
        margin-top: 100px;
        border-radius: 25px;
        outline: none;
        border: solid white 1px;
        box-shadow: none;
        cursor: pointer;
    }

    .learn-more {
        color: white;
    }

        .learn-more:hover {
            color: white;
            text-decoration: none;
        }

    .main-cards1 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 450px;
        grid-column-gap: 5vw;
        grid-row-gap: 5vh;
        margin-top: 100px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 100px;
    }

    .main-cards2 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 50px 150px 600px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 250px;
        height: 400px;
    }

    .main-cards3 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 32px 400px 400px;
        grid-row-gap: 30px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 100px;
    }

    .main-cards4 {
        display: grid;
        grid-template-columns: 40w 40w;
        grid-template-rows: 350px 350px;
        grid-row-gap: 6.67px;
        grid-column-gap: 15px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 150px;
    }

    .main-cards5 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 750px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 200px;
    }

    .card[type="experience"]:first-child {
        grid-column: 1;
        grid-row: 1;
        height: 100%;
        width: 100%;
    }

    .card[type="experience"]:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
        height: 100%;
        width: 150px;
    }

    .card[type="experience"]:nth-child(3) {
        grid-column: 1;
        grid-row: 3;
        height: 100%;
        width: 100%;
    }

    .text3 {
        text-align: left;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 28px;
        line-height: 28px;
        font-weight: 600;
        padding: 0;
        margin: 0;
    }

    .text4 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 20px;
        margin: 0;
    }

    .text5 {
        text-align: left;
        color: black;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        padding-top: 65px;
        margin: 0;
    }

    .text6 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .text7 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .profile {
        display: none;
    }

    .overlay[type=left] {
        position: absolute;
        width: 80vw;
        height: 50vw;
        background: #022140;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
    }

    .overlay[type=right] {
        position: absolute;
        width: 80vw;
        height: 50vw;
        background: #022140;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -70px;
    }

    .text8[type=right] {
        position: absolute;
        text-align: right;
        color: #21C4B9;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        margin-top: 20px;
        width: calc(80vw - 20px);
        z-index: 2;
    }

    .text8[type=left] {
        position: absolute;
        text-align: left;
        color: #21C4B9;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        margin-top: 20px;
        margin-left: 20px;
        z-index: 2;
    }

    .text9[type=right] {
        position: absolute;
        text-align: right;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
        padding: 0;
        margin: 0;
        margin-top: 40px;
        width: calc(80vw - 20px);
        z-index: 2;
    }

    .text9[type=left] {
        position: relative;
        text-align: left;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
        padding: 0;
        margin: 0;
        margin-top: 40px;
        margin-left: 20px;
        width: 250px;
        z-index: 2;
    }

    .text10[type=right] {
        position: absolute;
        text-align: right;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 75px;
        padding-left: 20px;
        width: calc(80vw - 20px);
        margin: 0;
        z-index: 2;
    }

    .text10[type=left] {
        position: absolute;
        text-align: left;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 5px;
        padding-left: 20px;
        width: calc(80vw - 20px);
        margin: 0;
        z-index: 2;
    }

    .text11[type="right"] {
        position: relative;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        padding-top: 20px;
        width: 80vw;
        z-index: 2;
    }

    .text11[type="left"] {
        position: relative;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 0;
        margin: 0;
        margin-top: 175px !important;
        padding-top: 20px;
        width: 80vw;
        z-index: 2;
    }

    .feature {
        width: 100%;
        z-index: 0;
        opacity: 0.2;
        transition: 0.5s;
        border-radius: 10px;
        z-index: 2
    }

    .contact-info {
        float: left;
        height: 400px;
        width: 75%;
        background: #022140;
        margin-top: 60px;
        margin-left: 12.5%;
        border-radius: 10px;
    }

    .contact-form {
        margin-top: 50px;
        background: white;
        width: 80vw;
        height: 500px;
        margin-left: 10vw;
        padding-top: 0px;
        border-radius: 10px;
    }

    .contact {
        width: 56vw;
        height: 50px;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-left: 20px;
        border-radius: 10px;
    }

    .message {
        width: 55vw;
        height: 200px;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 20px;
        resize: none;
        border-radius: 10px;
    }

    .send {
        width: 30vw;
        height: 50px;
        margin-top: 15px;
        margin-left: 15vw;
        font-family: 'Avenir Next';
        font-size: 20px;
        font-weight: 600;
        color: #EFF3FE;
        background: #022140;
        border: none;
        outline: none;
        border-radius: 10px;
        transition: 0.25s;
    }
}

@media only screen and (min-width: 50em) and (max-width: 62em) {
    .landing {
        position: relative;
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 200vw;
        width: 100vw;
        height: 100vh;
    }

    .text1 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 56px;
        line-height: 112px;
        font-weight: 700;
        padding: 0;
        margin: 0;
    }

    .text2 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }

    .clear {
        float: left;
        background: transparent;
        color: white;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 44px;
        font-weight: 400;
        width: 200px;
        margin-left: calc(50vw - 135px);
        margin-top: 100px;
        border-radius: 25px;
        outline: none;
        border: solid white 1px;
        box-shadow: none;
        cursor: pointer;
    }

    .learn-more {
        color: white;
    }

        .learn-more:hover {
            color: white;
            text-decoration: none;
        }

    .main-cards1 {
        display: grid;
        grid-template-columns: 50vw 25vw;
        grid-template-rows: 450px;
        grid-column-gap: 5vw;
        grid-row-gap: 5vh;
        margin-top: 100px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 100px;
    }

    .main-cards2 {
        display: grid;
        grid-template-rows: 50px 600px;
        grid-template-columns: 20vw 60vw;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 150px;
        height: 400px;
    }

    .main-cards3 {
        display: grid;
        grid-template-columns: 80vw;
        grid-template-rows: 32px 400px 400px;
        grid-row-gap: 30px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 150px;
    }

    .main-cards4 {
        display: grid;
        grid-template-columns: 40w 40w;
        grid-template-rows: 350px 350px;
        grid-row-gap: 6.67px;
        grid-column-gap: 15px;
        margin-top: 50px;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 200px;
    }

    .main-cards5 {
        display: grid;
        grid-template-columns: 800px;
        grid-template-rows: 750px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 200px;
    }

    .card[type="experience"]:first-child {
        grid-column: 1;
        grid-row: 1;
        height: 100%;
        width: 100%;
    }

    .card[type="experience"]:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
        height: 100%;
        width: 150px;
    }

    .card[type="experience"]:nth-child(3) {
        grid-column: 2;
        grid-row: 2;
        height: 100%;
        width: 100%;
    }

    .text3 {
        text-align: left;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 28px;
        line-height: 28px;
        font-weight: 600;
        padding: 0;
        margin: 0;
    }

    .text4 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 20px;
        margin: 0;
    }

    .text5 {
        text-align: left;
        color: black;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        padding-top: 20px;
        margin: 0;
    }

    .text6 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 12px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .text7 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .border {
        width: 100%;
        height: 37.5vw;
        margin-top: 75px;
        margin-left: 20px;
        border-radius: 5px;
        border: 2px solid #022140;
        box-shadow: 0px 0px 2px 0px #022140;
        z-index: 1;
        transition: 0.5s;
    }

    .profile {
        width: 100%;
        display: block;
        border-radius: 5px;
        z-index: 2;
        opacity: 0.75;
        transition: 0.5s;
    }

    .overlay[type=left] {
        position: absolute;
        width: 55%;
        height: 27.5vw;
        background: #188F88;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -70px;
    }

    .overlay[type=right] {
        position: absolute;
        width: 55%;
        height: 27.5vw;
        background: #188F88;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
        margin-left: 45%;
        margin-top: -70px;
    }

    .textbox[type=right] {
        position: absolute;
        width: 450px;
        height: 146px;
        background: #022140;
        z-index: 3;
        margin-top: 80px;
        margin-left: calc(80vw - 450px);
        border-radius: 10px;
    }

    .textbox[type=left] {
        position: absolute;
        width: 450px;
        height: 146px;
        background: #022140;
        z-index: 3;
        margin-top: 80px;
        margin-left: 0;
        border-radius: 10px;
    }

    .text8[type=right] {
        float: right;
        text-align: right;
        color: #188F88;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    .text8[type=left] {
        float: left;
        text-align: left;
        color: #188F88;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    .text9[type=right] {
        float: right;
        text-align: right;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        padding: 0;
        margin-top: 10px;
    }

    .text9[type=left] {
        float: left;
        text-align: left;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        padding: 0;
        margin-top: 10px;
    }

    .text10[type=right] {
        text-align: right;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 25px;
        margin: 0;
    }

    .text10[type=left] {
        text-align: left;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 25px;
        margin: 0;
    }

    .text11 {
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }

    .feature {
        width: 100%;
        z-index: 0;
        opacity: 0.75;
        transition: 0.5s;
        border-radius: 10px;
        z-index: 2
    }

        .feature:hover {
            opacity: 1;
            transition: 0.5s;
        }

    .contact-info {
        position: absolute;
        height: 400px;
        width: 275px;
        background: #022140;
        margin-top: 60px;
        border-radius: 10px;
    }

    .contact-form {
        margin-top: 50px;
        background: white;
        width: 100%;
        height: 500px;
        border-radius: 10px;
    }

    .contact {
        width: calc(60% - 20px);
        height: 50px;
        margin-left: 40%;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-left: 20px;
        border-radius: 10px;
    }

    .message {
        width: calc(60% - 40px);
        height: 200px;
        margin-left: 40%;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 20px;
        resize: none;
        border-radius: 10px;
    }

    .send {
        float: left;
        margin-left: 40%;
        width: 150px;
        height: 50px;
        margin-top: 15px;
        font-family: 'Avenir Next';
        font-size: 20px;
        font-weight: 600;
        color: #EFF3FE;
        background: #022140;
        border: none;
        outline: none;
        border-radius: 10px;
        transition: 0.25s;
    }
}

@media only screen and (min-width: 62em) and (max-width: 84em) {
    .landing {
        position: relative;
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 190vw;
        width: 100vw;
        height: 100vh;
    }

    .text1 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 56px;
        line-height: 112px;
        font-weight: 700;
        padding: 0;
        margin: 0;
    }

    .text2 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }

    .clear {
        float: left;
        background: transparent;
        color: white;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 44px;
        font-weight: 400;
        width: 200px;
        margin-left: calc(50vw - 135px);
        margin-top: 100px;
        border-radius: 25px;
        outline: none;
        border: solid white 1px;
        box-shadow: none;
        cursor: pointer;
    }

    .learn-more {
        color: white;
    }

        .learn-more:hover {
            color: white;
            text-decoration: none;
        }

    .main-cards1 {
        display: grid;
        grid-template-columns: 500px 300px;
        grid-template-rows: 600px;
        grid-column-gap: 5vw;
        grid-row-gap: 5vh;
        margin-top: 100px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 100px;
    }

    .main-cards2 {
        display: grid;
        grid-template-rows: 50px 600px;
        grid-template-columns: 200px 600px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 150px;
        height: 400px;
    }

    .main-cards3 {
        display: grid;
        grid-template-columns: 800px;
        grid-template-rows: 32px 400px 400px;
        grid-row-gap: 30px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 150px;
    }

    .main-cards4 {
        display: grid;
        grid-template-columns: 260px 260px 260px;
        grid-template-rows: 350px 350px;
        grid-row-gap: 6.67px;
        grid-column-gap: 15px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 200px;
    }

    .main-cards5 {
        display: grid;
        grid-template-columns: 800px;
        grid-template-rows: 750px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 200px;
    }

    .card[type="experience"]:first-child {
        grid-column: 1;
        grid-row: 1;
        height: 100%;
        width: 100%;
    }

    .card[type="experience"]:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
        height: 100%;
        width: 150px;
    }

    .card[type="experience"]:nth-child(3) {
        grid-column: 2;
        grid-row: 2;
        height: 100%;
        width: 100%;
    }

    .text3 {
        text-align: left;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        padding: 0;
        margin: 0;
    }

    .text4 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 20px;
        margin: 0;
    }

    .text5 {
        text-align: left;
        color: black;
        font-family: 'Avenir Next';
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        padding-top: 20px;
        margin: 0;
    }

    .text6 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .text7 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .border {
        width: 275px;
        height: 412px;
        margin-top: 75px;
        margin-left: 20px;
        border-radius: 5px;
        border: 2px solid #022140;
        box-shadow: 0px 0px 2px 0px #022140;
        z-index: 1;
        transition: 0.5s;
    }

    .profile {
        width: 275px;
        display: block;
        border-radius: 5px;
        z-index: 2;
        opacity: 0.75;
        transition: 0.5s;
    }

    .overlay[type=left] {
        position: absolute;
        width: 500px;
        height: 312px;
        background: #188F88;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -70px;
    }

    .overlay[type=right] {
        position: absolute;
        width: 500px;
        height: 312px;
        background: #188F88;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
        margin-left: calc(100% - 500px);
        margin-top: -70px;
    }

    .textbox[type=right] {
        position: absolute;
        width: 450px;
        height: 146px;
        background: #022140;
        z-index: 3;
        margin-top: 80px;
        margin-left: 350px;
        border-radius: 10px;
    }

    .textbox[type=left] {
        position: absolute;
        width: 450px;
        height: 146px;
        background: #022140;
        z-index: 3;
        margin-top: 80px;
        margin-left: 0;
        border-radius: 10px;
    }

    .text8[type=right] {
        float: right;
        text-align: right;
        color: #188F88;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    .text8[type=left] {
        float: left;
        text-align: left;
        color: #188F88;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    .text9[type=right] {
        float: right;
        text-align: right;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        padding: 0;
        margin-top: 10px;
    }

    .text9[type=left] {
        float: left;
        text-align: left;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        padding: 0;
        margin-top: 10px;
    }

    .text10[type=right] {
        text-align: right;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 25px;
        margin: 0;
    }

    .text10[type=left] {
        text-align: left;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 25px;
        margin: 0;
    }

    .text11 {
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }

    .feature {
        width: 100%;
        z-index: 0;
        opacity: 0.75;
        transition: 0.5s;
        border-radius: 10px;
        z-index: 2
    }

        .feature:hover {
            opacity: 1;
            transition: 0.5s;
        }

    .contact-info {
        position: absolute;
        height: 400px;
        width: 275px;
        background: #022140;
        margin-top: 60px;
        border-radius: 10px;
    }

    .contact-form {
        margin-top: 50px;
        background: white;
        width: 100%;
        height: 500px;
        border-radius: 10px;
    }

    .contact {
        width: calc(60% - 20px);
        height: 50px;
        margin-left: 40%;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-left: 20px;
        border-radius: 10px;
    }

    .message {
        width: calc(60% - 40px);
        height: 200px;
        margin-left: 40%;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 20px;
        resize: none;
        border-radius: 10px;
    }

    .send {
        float: left;
        margin-left: 40%;
        width: 150px;
        height: 50px;
        margin-top: 15px;
        font-family: 'Avenir Next';
        font-size: 20px;
        font-weight: 600;
        color: #EFF3FE;
        background: #022140;
        border: none;
        outline: none;
        border-radius: 10px;
        transition: 0.25s;
    }
}

@media only screen and (min-width: 84em) {
    .landing {
        position: relative;
        background: url("../../Assets/images/background.png") no-repeat center center fixed;
        background-size: 120vw;
        width: 100vw;
        height: 100vh;
    }

    .text1 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 64px;
        line-height: 128px;
        font-weight: 700;
        padding: 0;
        margin: 0;
    }

    .text2 {
        text-align: center;
        color: white;
        font-family: 'Avenir Next';
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }

    .clear {
        float: left;
        background: transparent;
        color: white;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 44px;
        font-weight: 400;
        width: 200px;
        margin-left: calc(50vw - 135px);
        margin-top: 100px;
        border-radius: 25px;
        outline: none;
        border: solid white 1px;
        box-shadow: none;
        cursor: pointer;
    }

    .learn-more {
        color: white;
    }

        .learn-more:hover {
            color: white;
            text-decoration: none;
        }

    .main-cards1 {
        display: grid;
        grid-template-columns: 500px 300px;
        grid-template-rows: 600px;
        grid-column-gap: 5vw;
        grid-row-gap: 5vh;
        margin-top: 100px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 100px;
    }

    .main-cards2 {
        display: grid;
        grid-template-rows: 50px 600px;
        grid-template-columns: 200px 600px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 150px;
        height: 400px;
    }

    .main-cards3 {
        display: grid;
        grid-template-columns: 800px;
        grid-template-rows: 32px 400px 400px;
        grid-row-gap: 30px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 150px;
    }

    .main-cards4 {
        display: grid;
        grid-template-columns: 260px 260px 260px;
        grid-template-rows: 350px 350px;
        grid-row-gap: 6.67px;
        grid-column-gap: 15px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 200px;
    }

    .main-cards5 {
        display: grid;
        grid-template-columns: 800px;
        grid-template-rows: 750px;
        margin-top: 50px;
        margin-left: calc(50vw - 400px);
        margin-right: calc(50vw - 400px);
        margin-bottom: 200px;
    }

    .card[type="experience"]:first-child {
        grid-column: 1;
        grid-row: 1;
        height: 100%;
        width: 100%;
    }

    .card[type="experience"]:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
        height: 100%;
        width: 150px;
    }

    .card[type="experience"]:nth-child(3) {
        grid-column: 2;
        grid-row: 2;
        height: 100%;
        width: 100%;
    }

    .text3 {
        text-align: left;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        padding: 0;
        margin: 0;
    }

    .text4 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 20px;
        margin: 0;
    }

    .text5 {
        text-align: left;
        color: black;
        font-family: 'Avenir Next';
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        padding-top: 20px;
        margin: 0;
    }

    .text6 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .text7 {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 10px;
        margin: 0;
    }

    .border {
        width: 275px;
        height: 412px;
        margin-top: 75px;
        margin-left: 20px;
        border-radius: 5px;
        border: 2px solid #022140;
        box-shadow: 0px 0px 2px 0px #022140;
        z-index: 1;
        transition: 0.5s;
    }

    .profile {
        width: 275px;
        display: block;
        border-radius: 5px;
        z-index: 2;
        opacity: 0.75;
        transition: 0.5s;
    }

    .overlay[type=left] {
        position: absolute;
        width: 500px;
        height: 312px;
        background: #188F88;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -70px;
    }

    .overlay[type=right] {
        position: absolute;
        width: 500px;
        height: 312px;
        background: #188F88;
        z-index: 1;
        border-radius: 10px;
        cursor: pointer;
        margin-left: calc(100% - 500px);
        margin-top: -70px;
    }

    .textbox[type=right] {
        position: absolute;
        width: 450px;
        height: 146px;
        background: #022140;
        z-index: 3;
        margin-top: 80px;
        margin-left: 350px;
        border-radius: 10px;
    }

    .textbox[type=left] {
        position: absolute;
        width: 450px;
        height: 146px;
        background: #022140;
        z-index: 3;
        margin-top: 80px;
        margin-left: 0;
        border-radius: 10px;
    }

    .text8[type=right] {
        float: right;
        text-align: right;
        color: #188F88;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    .text8[type=left] {
        float: left;
        text-align: left;
        color: #188F88;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    .text9[type=right] {
        float: right;
        text-align: right;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        padding: 0;
        margin-top: 10px;
    }

    .text9[type=left] {
        float: left;
        text-align: left;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        padding: 0;
        margin-top: 10px;
    }

    .text10[type=right] {
        text-align: right;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 25px;
        margin: 0;
    }

    .text10[type=left] {
        text-align: left;
        color: #EFF3FE;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding-top: 25px;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 25px;
        margin: 0;
    }

    .text11 {
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }

    .feature {
        width: 100%;
        z-index: 0;
        opacity: 0.75;
        transition: 0.5s;
        border-radius: 10px;
        z-index: 2
    }

        .feature:hover {
            opacity: 1;
            transition: 0.5s;
        }

    .contact-info {
        position: absolute;
        height: 400px;
        width: 275px;
        background: #022140;
        margin-top: 60px;
        border-radius: 10px;
    }

    .contact-form {
        margin-top: 50px;
        background: white;
        width: 100%;
        height: 500px;
        border-radius: 10px;
    }

    .contact {
        width: calc(60% - 20px);
        height: 50px;
        margin-left: 40%;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-left: 20px;
        border-radius: 10px;
    }

    .message {
        width: calc(60% - 40px);
        height: 200px;
        margin-left: 40%;
        background: #EFF3FE;
        color: #022140;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: none;
        outline: none;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 20px;
        resize: none;
        border-radius: 10px;
    }

    .send {
        float: left;
        margin-left: 40%;
        width: 150px;
        height: 50px;
        margin-top: 15px;
        font-family: 'Avenir Next';
        font-size: 20px;
        font-weight: 600;
        color: #EFF3FE;
        background: #022140;
        border: none;
        outline: none;
        border-radius: 10px;
        transition: 0.25s;
    }
}

.about {
    position: relative;
    background: white !important;
    width: 100vw;
    height: 100vh;
}

.card {
    background-color: transparent;
}

    .card[type="projects"] {
        border-radius: 10px;
        background: #022140;
    }

    .card[type="projects"]:hover {
        box-shadow: 0px 0px 3px 0px black;
        transform: translate(0, -5px);
        transition: 0.25s;
    }

    .card[type="about"]:first-child {
        grid-column: 1;
        grid-row: 1;
        height: 100%;
        width: 100%;
    }

    .card[type="portfolio"]:first-child {
        grid-column: 1;
        grid-row: 1;
        height: 100%;
        width: 100%;
    }

    .card[type="about"]:nth-child(2) {
        grid-column: 2;
        grid-row: 2;
        height: 100%;
        width: 100%;
    }

    .card[type="portfolio"]:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
        height: 100%;
        width: 100%;
    }

    .card[type="portfolio"]:nth-child(3) {
        grid-column: 1;
        grid-row: 3;
        height: 100%;
        width: 100%;
    }

.container1 {
    position: relative;
    height: 400px;
    padding-top: calc(50vh - 200px);
    background: transparent;
}

.text12 {
    text-align: left;
    color: #EFF3FE;
    font-family: 'Avenir Next';
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    padding-top: 50px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 25px;
    margin: 0;
}

.text13 {
    text-align: left;
    color: #EFF3FE;
    font-family: 'Avenir Next';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 25px;
    margin: 0;
}

.text14 {
    text-align: left;
    color: #188F88;
    font-family: 'Avenir Next';
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 25px;
    margin: 0;
}

    .border:hover {
        border: 1px solid #022140;
        box-shadow: 0px 0px 1px 0px #022140;
        transition: 0.5s;
    }

    .profile:hover {
        opacity: 1;
        transition: 0.5s;
    }

.project-main {
    height: 300px;
}

.workplace {
    width: 125px;
    height: 50px;
    font-size: 16px;
    font-family: 'Avenir Next';
    font-weight: 500;
    text-align: left;
    text-indent: 20px;
    color: #6B6B6B;
    background: white;
    outline: none;
    border: none;
    box-shadow: none;
    margin-left: -36px;
    padding-left: 0;
    cursor: pointer;
    z-index: 2;
}

.slider-container-home {
    position: absolute;
    margin-top: 67px;
    margin-left: -43px;
    width: 100px;
    height: 4px;
    transform: rotate(90deg);
}

    .slider-container-home .bar {
        position: absolute;
        z-index: 0;
        border-radius: 20px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-color: #6B6B6B;
        opacity: 1;
        box-shadow: none;
    }

    /* The slider itself */
    .slider-container-home .slider {
        position: relative;
        z-index: 0 !important;
        -webkit-appearance: none; /* Override default CSS styles */
        width: 100%; /* Full-width */
        height: 0px; /* Specified height */
        background: transparent; /* Grey background */
        border-radius: 25px;
        outline: none; /* Remove outline */
        opacity: 1; /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: .2s; /* 0.2 seconds transition on hover */
        transition: opacity .2s;
    }
        /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
        .slider-container-home .slider::-webkit-slider-thumb {
            -webkit-appearance: none; /* Override default look */
            width: 50px; /* Set a specific slider handle width */
            height: 4px; /* Slider handle height */
            background: #022140; /* Green background */
            border-radius: 25px;
            box-shadow: none;
            margin-top: -18px;
            margin-left: 0px;
        }

.overlay[type=profile] {
    float: left;
    width: 100%;
    height: 100%;
    background: #022140;
    z-index: 1;
    border-radius: 10px;
    margin-top: -20px;
    margin-left: -20px;
}

    .textbox:hover {
        box-shadow: 0px 0px 3px 0px black;
        transition: 0.1s;
    }

    .contact::placeholder {
        text-align: left;
        color: #6B6B6B;
        font-family: 'Avenir Next';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin: 0;
    }

    .message::-webkit-scrollbar {
        width: 5px;
    }

        .message::-webkit-scrollbar-track-piece {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .message::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background-color: #6B6B6B;
        }

        .message::-webkit-scrollbar-corner {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        }

        .message::-webkit-scrollbar-button {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        }

        .message::-webkit-resizer {
            display: none;
        }

    .send:hover {
        background: white;
        color: #022140;
        box-shadow: 0 0 5px 0 #022140;
        cursor: pointer;
        transition: 0.25s;
    }

    .contact-info:hover {
        box-shadow: 0 0 3px 0 black;
    }

.landing {
    border-radius: 0 !important;
}

.card[type="experience"] {
    outline: none;
    border: none;
    background: transparent;
}

.card[type="about"] {
    outline: none;
    border: none;
}

.card[type="projects"] {
    outline: none;
    border: none;
}

.card[type="portfolio"] {
    outline: none;
    border: none;
}

.card[type="contact"] {
    outline: none;
    border: none;
}