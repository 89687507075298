@media only screen and (max-width: 58em) {
    body::-webkit-scrollbar {
        width: 2.5px;
    }

        body::-webkit-scrollbar-track-piece {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            margin-top: -20px;
            margin-bottom: -20px;
        }

        body::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background-color: #6B6B6B;
        }

        body::-webkit-scrollbar-corner {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        }

        body::-webkit-scrollbar-button {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        }

        body::-webkit-resizer {
            display: none;
        }
}

@media only screen and (min-width: 58em) {
    body::-webkit-scrollbar {
        width: 5px;
    }

        body::-webkit-scrollbar-track-piece {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            margin-top: -20px;
            margin-bottom: -20px;
        }

        body::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background-color: #6B6B6B;
        }

        body::-webkit-scrollbar-corner {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        }

        body::-webkit-scrollbar-button {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        }

        body::-webkit-resizer {
            display: none;
        }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
    font-family: 'GothamBold';
    src: url("./Assets/fonts/Gotham Bold.TTF");
}

@font-face{
    font-family: 'AvenirNext-Medium';
    src: url("./Assets/fonts/AvenirNext-Medium.TTF");
}
