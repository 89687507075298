.feedback-body {
    background: #ECECED;
    padding-bottom: 400px;
}

@media only screen and (max-width: 34em) {
    .main-cards[type=feedback] {
        grid-template-columns: 90%;
        grid-template-rows: 1000px;
    }

    .card[type=feedback]:first-child {
        display: none;
    }

    .card[type=feedback]:nth-child(2) {
        grid-row: 1;
        grid-column: 1;
        background-color: #48AC6D;
        outline: none;
        border: none;
        border-radius: 25px;
        margin-left: 5%;
        height: 900px;
        width: 80%;
        margin-top: 175px;
    }

    .feedback-header {
        text-align: center;
        font-family: GothamBold;
        font-size: 38px;
        color: #48B08C;
        margin-left: 0px;
        margin-top: -140px;
        margin-bottom: 25px;
    }

    .drop-button[type=terrafarm] {
        float: left;
        width: 60vw;
        margin-top: 50px;
        padding: 7.5px;
        text-align: center;
        color: #787878 !important;
        font-family: 'Avenir Next';
        font-weight: 600;
        font-size: 20px;
        background-color: #D2E9D5;
        border-radius: 25px;
        border: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        color: #232323;
        text-decoration: none;
    }

    .dropdown-menu[type=feedback] {
        position: relative;
        text-indent: 2.5%;
        width: 60vw;
        margin-top: 0px !important;
        padding-bottom: 10px;
        border-radius: 0 0 25px 25px;
        background-color: #D2E9D5;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }

    .button[type=green] {
        position: absolute;
        margin-top: 770px;
        margin-left: 20%;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 50%;
        height: 45px;
        font-size: 24px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .feedback-input[type=comments] {
        position: absolute;
        width: 70%;
        height: 12% !important;
        background-color: #D2E9D5;
        font-family: 'Avenir Next';
        font-weight: 500;
        color: #787878;
        font-size: 18px;
        border-radius: 25px;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-left: 20px !important;
        padding-right: 25px !important;
        text-indent: 0px !important;
        padding: 1px;
        border-width: 0 0 2px;
        border-color: #D6D6D6;
        margin-top: 550px;
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 34em) and (max-width: 45em) {
    .main-cards[type=feedback] {
        grid-template-columns: 90%;
        grid-template-rows: 1000px;
    }

    .card[type=feedback]:first-child {
        display: none;
    }

    .card[type=feedback]:nth-child(2) {
        grid-row: 1;
        grid-column: 1;
        background-color: #48AC6D;
        outline: none;
        border: none;
        border-radius: 25px;
        margin-left: 6%;
        height: 900px;
        width: 80%;
        margin-top: 175px;
    }

    .feedback-header {
        text-align: center;
        font-family: GothamBold;
        font-size: 44px;
        color: #48B08C;
        margin-left: 0px;
        margin-top: -140px;
        margin-bottom: 25px;
    }

    .drop-button[type=terrafarm] {
        float: left;
        width: 60vw;
        margin-top: 50px;
        padding: 7.5px;
        text-align: center;
        color: #787878 !important;
        font-family: 'Avenir Next';
        font-weight: 600;
        font-size: 24px;
        background-color: #D2E9D5;
        border-radius: 25px;
        border: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        color: #232323;
        text-decoration: none;
    }

    .dropdown-menu[type=feedback] {
        position: relative;
        text-indent: 5%;
        width: 60vw;
        margin-top: 0px !important;
        padding-bottom: 10px;
        border-radius: 0 0 25px 25px;
        background-color: #D2E9D5;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }

    .button[type=green] {
        position: absolute;
        margin-top: 770px;
        margin-left: 25%;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 50%;
        height: 45px;
        font-size: 24px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .feedback-input[type=comments] {
        position: absolute;
        width: 75%;
        height: 12% !important;
        background-color: #D2E9D5;
        font-family: 'Avenir Next';
        font-weight: 500;
        color: #787878;
        font-size: 18px;
        border-radius: 25px;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-left: 20px !important;
        padding-right: 25px !important;
        text-indent: 0px !important;
        padding: 1px;
        border-width: 0 0 2px;
        border-color: #D6D6D6;
        margin-top: 550px;
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 45em) and (max-width: 100em) {
    .main-cards[type=feedback] {
        grid-template-columns: 650px;
        grid-template-rows: 1000px;
    }

    .card[type=feedback]:first-child {
        display: none;
    }

    .card[type=feedback]:nth-child(2) {
        grid-row: 2;
        grid-column: 1;
        background-color: #48AC6D;
        outline: none;
        border: none;
        border-radius: 25px;
        margin-left: calc(50vw - 325px);
        height: 900px;
        width: 600px;
        margin-top: 175px;
    }

    .feedback-header {
        text-align: center;
        font-family: GothamBold;
        font-size: 44px;
        color: #48B08C;
        margin-left: 0px;
        margin-top: -140px;
        margin-bottom: 25px;
    }

    .drop-button[type=terrafarm] {
        float: left;
        width: 329px;
        margin-top: 50px;
        padding: 7.5px;
        text-align: center;
        color: #787878 !important;
        font-family: 'Avenir Next';
        font-weight: 600;
        font-size: 24px;
        background-color: #D2E9D5;
        border-radius: 25px;
        border: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        color: #232323;
        text-decoration: none;
    }

    .dropdown-menu[type=feedback] {
        position: relative;
        text-indent: 25px;
        width: 329px;
        margin-top: 0px !important;
        padding-bottom: 10px;
        border-radius: 0 0 25px 25px;
        background-color: #D2E9D5;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }

    .button[type=green] {
        position: absolute;
        margin-top: 770px;
        margin-left: 230px;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 36%;
        height: 45px;
        font-size: 24px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .feedback-input[type=comments] {
        position: absolute;
        width: 75%;
        height: 12% !important;
        background-color: #D2E9D5;
        font-family: 'Avenir Next';
        font-weight: 500;
        color: #787878;
        font-size: 18px;
        border-radius: 25px;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-left: 20px !important;
        padding-right: 25px !important;
        text-indent: 0px !important;
        padding: 1px;
        border-width: 0 0 2px;
        border-color: #D6D6D6;
        margin-top: 550px;
        overflow-y: scroll;
    }
}


@media only screen and (min-width: 100em) {
    .main-cards[type=feedback] {
        column-count: 2;
        column-gap: 20px;
        margin: 20px;
    }

    .main-cards[type=feedback] {
        grid-template-columns: 650px 600px;
        grid-template-rows: 900px;
    }

    .card[type=feedback]:first-child {
        grid-row: 1;
        grid-column: 1;
        background-color: transparent;
        box-shadow: none;
        outline: none;
        border: none;
        height: 900px;
        width: 650px;
    }

    .card[type=feedback]:nth-child(2) {
        grid-row: 1;
        grid-column: 2;
        background-color: #48AC6D;
        outline: none;
        border: none;
        border-radius: 25px;
        margin-left: 15px;
        height: 900px;
        width: 600px;
    }

    .feedback-header {
        display: none;
    }

    .drop-button[type=terrafarm] {
        float: left;
        width: 329px;
        margin-top: 50px;
        padding: 7.5px;
        text-align: center;
        color: #787878 !important;
        font-family: 'Avenir Next';
        font-weight: 600;
        font-size: 24px;
        background-color: #D2E9D5;
        border-radius: 25px;
        border: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;
        color: #232323;
        text-decoration: none;
    }

    .dropdown-menu[type=feedback] {
        position: relative;
        text-indent: 25px;
        width: 329px;
        margin-top: 0px !important;
        padding-bottom: 10px;
        border-radius: 0 0 25px 25px;
        background-color: #D2E9D5;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }

    .button[type=green] {
        position: absolute;
        margin-top: 770px;
        margin-left: 230px;
        background-color: #DE5E3B;
        font-family: GothamBold;
        border: 0;
        width: 36%;
        height: 45px;
        font-size: 24px;
        border-radius: 25px;
        color: #ECECED;
        cursor: pointer;
        box-shadow: 0 0 5px #FF6D45;
    }

    .feedback-input[type=comments] {
        position: absolute;
        width: 75%;
        height: 12% !important;
        background-color: #D2E9D5;
        font-family: 'Avenir Next';
        font-weight: 500;
        color: #787878;
        font-size: 18px;
        border-radius: 25px;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-left: 20px !important;
        padding-right: 25px !important;
        text-indent: 0px !important;
        padding: 1px;
        border-width: 0 0 2px;
        border-color: #D6D6D6;
        margin-top: 550px;
        overflow-y: scroll;
    }
}

.main[type=feedback] {
    grid-area: main;
    background-color: #ECECED;
}

.card[type=feedback] {
    font-family: AvenirNext-Medium;
    font-size: 16px;
    color: #232323;
    float: left;
    align-items: start;
    width: 100%;
    background-color: white;
    -webkit-column-break-inside: avoid;
    padding: 75px;
    padding-top: 25px;
    box-sizing: border-box;
    box-shadow: 0 0 5px #BABABA;
    margin: 1em 10%;
}

.card[type="feedback"] h1 {
    text-align: left;
    font-family: GothamBold;
    font-size: 120px;
    color: #48B08C;
    line-height: 132px;
    margin-left: 0px;
}

.card[type="feedback"] h2 {
    text-align: left;
    font-family: 'Avenir Next';
    font-weight: 600;
    font-size: 18px;
    color: #787878;
    line-height: 26px;
    margin-left: 0px;
    margin-top: 20px;
}

.card[type="feedback"] h3 {
    text-align: left;
    font-family: GothamBold;
    font-weight: 600;
    font-size: 24px;
    color: white;
    margin-left: 0px;
    margin-top: 50px;
    margin-bottom: 25px;
}

.feedback-input[type=ID] {
    position: absolute;
    width: 72%;
    background-color: transparent;
    font-family: 'Avenir Next';
    font-weight: 500;
    color: #D6D6D6;
    font-size: 24px;
    padding-top: 7.5px !important;
    padding: 1px;
    border-width: 0 0 2px;
    border-color: #D6D6D6;
    margin-top: 30px;
}

.feedback-input[type=weight] {
    position: absolute;
    width: 22%;
    background-color: transparent;
    font-family: 'Avenir Next';
    font-weight: 500;
    color: #D6D6D6;
    font-size: 24px;
    padding-top: 7.5px !important;
    padding: 1px;
    border-width: 0 0 2px;
    border-color: #D6D6D6;
    margin-top: 242px;
    margin-left: 150px;
}
    .feedback-input::-webkit-scrollbar {
        width: 5px;
    }

        .feedback-input::-webkit-scrollbar-track-piece {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .feedback-input::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .feedback-input::-webkit-scrollbar-corner {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        }

        .feedback-input::-webkit-scrollbar-button {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        }

        .feedback-input::-webkit-resizer {
            display: none;
        }

    .feedback-input[type=comments]::-webkit-input-placeholder {
        color: #787878;
    }

    .feedback-input[type=weight]::-webkit-input-placeholder, .feedback-input[type=ID]::-webkit-input-placeholder {
        color: #D6D6D6;
    }

.feedback-input:focus {
    outline: none;
}

.drop-button[type=terrafarm]:hover {
    text-decoration: none;
}

.drop-button[type=terrafarm]:focus {
    border-radius: 25px 25px 0 0;
}

.drop-button[type=crop] {
    float: left;
    width: 329px;
    margin-top: 50px;
    padding: 7.5px;
    text-align: center;
    color: #787878 !important;
    font-family: 'Avenir Next';
    font-weight: 600;
    font-size: 24px;
    background-color: #D2E9D5;
    border-radius: 25px;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    color: #232323;
}

.drop-button[type=crop]:hover {
    text-decoration: none;
}

.drop-button[type=crop]:focus {
    border-radius: 25px 25px 0 0;
}

.dropdown-item[type=terrafarm] > a {
    display: block;
    padding: 0;
    font-family: AvenirNext-Medium;
    font-size: 18px;
    text-align: left;
    text-decoration: none;
    color: #787878;
}

.dropdown-item[type=terrafarm] > a:hover {
    color: #DE5E3B;
}

.dropdown-item[type=crop] > a {
    display: block;
    padding: 0;
    font-family: AvenirNext-Medium;
    font-size: 18px;
    text-align: left;
    text-decoration: none;
    color: #787878;
}

    .dropdown-item[type=crop] > a:hover {
        color: #DE5E3B;
    }

    .button[type=green]:hover {
        outline: none;
        border: none;
        box-shadow: none;
        background-color: #D2E9D5;
        box-shadow: 0 0 5px #FF6D45;
        color: #DE5E3B;
    }

    .button[type=green]:focus {
        background-color: #D2E9D5;
        color: #DE5E3B;
        box-shadow: 0 0 5px #FF6D45;
        border: none;
        outline: none;
    }